import { Absolute, CMargin, DText, Flex } from '../../../styles/CommonStyles'
import BankCard from '../../../core/packages/bank-service/BankCard'
import getBankInfo from '../../../core/packages/bank-service/Bank'
import { BankStatus, BankWrapper, CardsAnimate } from '../../../styles/main/profile'
import Text from '../../../core/utils/Text'
import { formatDate } from '../../../core/utils/common'
import { useMainContext } from '../../../core/contexts/main'
import { fadeVariants } from '../../../core/utils/theme'
import Dropdown from '../../common/dropdown/Dropdown'
import useGetProfileOrUser from '../../../core/hooks/main/users/useGetProfileOrUser'
import NoData from '../../common/utils/NoData'
import { bankAccountOperations } from '../../../core/constants/operation'
import { useState } from 'react'
import UserBankActions from '../users/UserBankActions'

const ProfileBanks = (props) => {
	const { detail = false, user = null } = props

	const { lang } = useMainContext()

	const { profile } = useGetProfileOrUser(detail, user)
	const banks = profile?.banks

	const actionInitial = {
		show: false,
		action: null,
		bank: null,
	}
	const [actionMode, setActionMode] = useState(actionInitial)
	const closeActionMode = () => setActionMode(actionInitial)

	const onOptionsClicked = (idx, bank) => {
		setActionMode({
			show: true,
			action: bankAccountOperations[idx],
			bank,
		})
	}

	return (
		<>
			{actionMode.show ? (
				<UserBankActions {...props} mode={actionMode} closeActionMode={closeActionMode} />
			) : (
				<CardsAnimate variants={fadeVariants} animate='in' exit='out' initial='out'>
					{banks?.map((bank) => {
						const bankInfo = getBankInfo(bank.cardNo)
						console.log(bank.cardNo, bankInfo)
						if (!bankInfo) return <></>
						return (
							<BankWrapper key={bank.verifyAt}>
								{detail && (
									<Absolute top={'8px'} left={'0'} width={'32px'}>
										<Dropdown
											options={bankAccountOperations}
											onOptionClicked={(idx) => onOptionsClicked(idx, bank)}
											minWidth={'160px'}
										/>
									</Absolute>
								)}
								<BankCard bankInfo={bankInfo} bankAccount={bank} />
								<CMargin margin='10px' />
								<Flex fw justify={'space-around'}>
									<Flex>
										<DText main>
											<Text tid='status' /> :
										</DText>
										<BankStatus verified={bank.status === 'VERIFIED'}>
											<Text tid={bank.status} />
										</BankStatus>
									</Flex>
									<Flex>
										<DText main>
											<Text tid='verify-date' /> :
										</DText>
										<DText main>
											{bank.verifyAt ? (
												formatDate(bank.verifyAt, 'date', lang)
											) : (
												<Text tid='not-verified' />
											)}
										</DText>
									</Flex>
								</Flex>
							</BankWrapper>
						)
					})}
					{!banks?.length && <NoData desc={'no-bank-found'} />}
				</CardsAnimate>
			)}
		</>
	)
}

export default ProfileBanks
